import React, { useEffect, useState } from 'react';

import { Clock } from 'lucide-react';

export default function CustomeTimer({ isJoin, stime }) {

    const [elTime, setElTime] = useState('00:00:00');

    useEffect(() => {
        if (isJoin) {
            const timer = setInterval(() => {
                const now = new Date();
                const start = stime;

                const elapsed = now - start;

                const hours = String(Math.floor((elapsed / (1000 * 60 * 60)) % 24)).padStart(2, '0');
                const minutes = String(Math.floor((elapsed / (1000 * 60)) % 60)).padStart(2, '0');
                const seconds = String(Math.floor((elapsed / 1000) % 60)).padStart(2, '0');

                setElTime(`${hours}:${minutes}:${seconds}`);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [isJoin, stime]);

    return (
        <div className='h-10 text-sm text-white bg-prime mx-2 px-4 rounded-2xl flex items-center justify-center cursor-pointer space-x-2'>
            <Clock size={16} /><span>{elTime} Min</span>
        </div>
    )
}
